//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    variant: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
    border: {
      type: Boolean,
      default: true
    },
    icon: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes () {
      let cls = ''

      if (this.size) {
        cls += ` badge--${this.size}`
      }

      if (this.variant) {
        switch (this.variant) {
          case 'green':
          case 'success':
            cls += ' badge-success'
            break
          case 'blue':
          case 'info':
          case 'primary':
            cls += ' badge-info'
            break
          case 'red':
          case 'error':
            cls += ' badge-error'
            break
          case 'error-dark':
            cls += ' badge-error--dark'
            break
          case 'pink':
          case 'secondary':
            cls += ' badge-secondary'
            break
          case 'yellow':
          case 'warning':
            cls += ' badge-warning'
            break
          case 'gray':
          case 'disabled':
            cls += ' badge-disabled'
            break
        }

        if (this.filled) {
          cls += '--dark'
        }

        if (this.icon) {
          cls += ' badge-icon'
        }

        if (!this.border) {
          cls += ' borderless'
        }
      } else {
        switch (this.type) {
          case 'brand':
            cls = 'badge-partner badge-brand'
            break
          case 'store':
            cls = 'badge-partner badge-store'
            break
          default:
            cls += ' badge-' + this.type
            break
        }

        if (this.homepage) {
          cls += ' homepage'
        }

        if (this.icon) {
          cls += ' icon'
        }
      }

      return cls
    }
  }
}
